import { Helmet } from 'react-helmet';
import faqsList from '../data/faqs';
import SectionPageTitle from '../components/SectionPageTitle';
import Faq from '../components/Faq';
import SocialLinks from '../components/SocialLinks';
import CallToAction from '../components/CallToAction';
import mission from '../imgs/mission.webp';
import daniel from '../imgs/team/daniel.webp';
import albert from '../imgs/team/albert.webp';
import lucia from '../imgs/team/lucia.webp';
import philip from '../imgs/team/philip.webp';

export default function About() {
	return (
		<>
			<Helmet>
				<title>About Us - Tech Corp: Business Services & IT Solutions React js Website Template</title>
				<meta name="description" content="Our mission is to drive your success through innovative and tailored IT solutions" />
				<meta name="keywords" content="team it solution, expert team, about, mission, it solutions, business" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'About Us'} 
				subTitle={'Our history'}
			/>

			<section className="container grid g-2">
				<div className="print flex">
					<img src={mission} alt="About us - Tech Corp: Business Services & IT Solutions HTML Website Template" />
				</div>

				<article>
					<h1>Who <span className="txt-gradient">We are</span></h1>

					<h2>Much more than an IT team</h2>
					<p>Welcome to Tech Corp, where innovation meets reliability. Founded on the principles of cutting-edge technology and unwavering commitment to excellence, we are your trusted partner for business services and IT solutions.</p>
					<p>At Tech Corp, we are a team of passionate professionals dedicated to empowering businesses with transformative technology solutions. With a rich history of successful partnerships and a forward-thinking approach, we strive to be at the forefront of the ever-evolving tech landscape.</p>

					<h2>Our Mission</h2>
					<p className="mb-3">Our mission is to drive your success through innovative and tailored IT solutions. We believe in the power of technology to propel businesses forward, and we are committed to delivering solutions that not only meet but exceed your expectations.</p>
				</article>
			</section>
			
			<section className="accent-end">
				<div className="container mb-8">
					<h1 className="txt-gradient">We Have Expert Team</h1>
					<div className="grid gtc-4 g-2">
						<article>
							<img src={daniel} className="mb-1" alt="Tech Corp team" />
							<h2 className="mb-0">Daniel Grayson</h2>
							<p>CEO</p>
							<SocialLinks />
						</article>
						
						<article>
							<img src={lucia} className="mb-1" alt="Tech Corp team" />
							<h2 className="mb-0">Lucia Mason</h2>
							<p>Head Manager</p>
							<SocialLinks />
						</article>

						<article>
							<img src={philip} className="mb-1" alt="Tech Corp team" />
							<h2 className="mb-0">Philip Jopher</h2>
							<p>Developer</p>
							<SocialLinks />
						</article>

						<article>
							<img src={albert} className="mb-1" alt="Tech Corp team" />
							<h2 className="mb-0">Albert Coffin</h2>
							<p>Chief Expert</p>
							<SocialLinks />
						</article>
					</div>
				</div>
			</section>


			<section className="container">
				<h1 className="txt-center mb-4">Frequently Asked <span className="txt-gradient">Questions</span></h1>

				<div className="grid g-2 mb-8">
					<article>
						{faqsList.slice(0, 4).map((faq, idx) =>
							<Faq
								key={idx}
								question={faq.question}
								answer={faq.answer}
							/>
						)}
					</article>

					<ul>
						<li>
							<h3 className='mb-3'>Tech Corp Solutions:</h3>
						</li>
						<li>
							<h2><span className="pictogram" /> IT Consultancy</h2>
						</li>
						<li>
							<h2><span className="pictogram" /> Software Development</h2>
						</li>
						<li>
							<h2 className="mb-0"><span className="pictogram" /> Cybersecurity</h2>
						</li>
					</ul>
				</div>

<CallToAction
	icon={<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"/></svg>}
	text="We're Delivering the best customer Experience"
	btnLabel="Contact Us Now"
	btnUrl="/contact"
/>
			</section>

		</>
	);
}