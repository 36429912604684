import { Link } from 'react-router-dom';
import servicesList from '../data/services';

export default function ServicesWidget() {
	return (
		<ul>
			<li>
				<h4>All Services</h4>
			</li>
			{servicesList.map(service =>
				<li className="grid gtc-1-2 g-1" key={service.slug}>
					{service.icon}
					<h5>
						<Link to={`/services/${service.slug}`}>{service.title}</Link>
					</h5>
				</li>
			)}
		</ul>
	);
}