import reviewsList from '../data/reviews';
import Review from '../components/Review';

export default function SectionReviews({ col=3 }) {
	return (
		<section>
			<span className="sr-only offset-nav" id="reviews" />
			<div className="container">
				<h1 className="txt-center">Clients <span className="txt-gradient">Reviews</span></h1>

				<div className={`grid gtc-${col} g-2`}>
					{reviewsList.map((review, idx) =>
						<Review
							key={idx}
							name={review.name}
							role={review.role}
							stars={review.stars}
							img={review.img}
							text={review.text}
						/>
					)}
				</div>
			</div>
		</section>
	);
}