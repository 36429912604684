import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import servicesList from '../data/services';
import faqsList from '../data/faqs';
import SectionPageTitle from '../components/SectionPageTitle';
import PageNotFound from './PageNotFound';
import Faq from '../components/Faq';
import SocialShare from '../components/SocialShare';
import ServicesWidget from '../components/ServicesWidget';
import CallToAction from '../components/CallToAction';

export default function ServiceLayout() {
	const { serviceName } = useParams(),
			service = servicesList.filter(service => service.slug.includes(serviceName))[0];
			
	if (service) {
		return (
			<>
				<Helmet>
					<title>{service.title} - Services - Tech Corp: Business Services & IT Solutions React js Website Template</title>
				</Helmet>

				<SectionPageTitle
					pageTitle={service.title}
				/>

				<section className="container grid-sidebar">
					<article>
						{service.content}

						{faqsList.map((faq, idx) =>
							<Faq
								key={idx}
								question={faq.question}
								answer={faq.answer}
							/>
						)}

						<SocialShare title={service.title} />
					</article>

					<aside>
						<ServicesWidget />
					</aside>
				</section>

				<span className="sr-only offset-nav" id="contact" />
				<CallToAction
					icon={<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"/></svg>}
					text="We're Delivering the best customer Experience"
					btnLabel="Contact Us Now"
					btnUrl="/contact"
					customClass="mb-8"
				/>
			</>
		);
	} else {
		return <PageNotFound />
	}
}