import { Helmet } from 'react-helmet';
import postsList from '../data/posts';
import SectionPageTitle from '../components/SectionPageTitle';
import PostPreview from '../components/PostPreview';
import SectionContact from '../components/SectionContact';

export default function Blog() {
	return (
		<>
			<Helmet>
				<title>Blog - Tech Corp: Business Services & IT Solutions React js Website Template</title>
				<meta name="description" content="All the answers, the updates and news regarding the world of Business Services & IT Solutions" />
				<meta name="keywords" content="post it solutions, business posts, services update, post, blog, news" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'Blog'} 
				subTitle={'Stay up to date'}
			/>

			<section className="container">
				<p className="grid mb-3">Stay updated through our articles: here you will find all the answers to your doubts and all the updates and news regarding the world of Business Services & IT Solutions.</p>

				<div className="grid g-2 gtc-3">
					{postsList.map((post, idx) =>
						<PostPreview
							key={idx}
							img={post.imgs.v}
							title={post.title}
							slug={post.slug}
							date={post.date}
							category={post.category}
						/>
					)}
				</div>
			</section>

			<SectionContact />
		</>
	);
}