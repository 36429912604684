export default function Review({ name, role, stars, img, text }) {
	let starsBlock = [];
	for (let i=0; i < stars; i++) {
		starsBlock.push(<span className="star" key={i} />);
	}

	return (
		<article className="review">
			<img src={img} alt={name + ' - Review Tech Corp React js template'} />
			<div className="word-wrap">
				<h2>{name}</h2>
				<p><em>{role}</em></p>

				<span>
					{starsBlock}
				</span>
			</div>

			<p>{text}</p>
		</article>
	);
}