import { Helmet } from 'react-helmet';
import CallToAction from '../components/CallToAction';

export default function Policy() {
	return (
		<>
			<Helmet>
				<title>Policy - Tech Corp: Business Services & IT Solutions React js Website Template</title>
			</Helmet>

			<section className="container">
				<h1>Privacy Policy</h1>
				<h3>Who we are</h3>
				<p>Our website address is: https://yourdomain.com.</p>

				<h3>Comments</h3>
				<p>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor's IP address and browser user agent string to help spam detection.</p>

				<h3>Average</h3>
				<p>If you upload images to the website, you should avoid uploading images that include embedded location data (EXIF GPS). Website visitors can download and extract any location data from images on the website.</p>

				<h3>Cookies</h3>
				<p>If you leave a comment on our site you may choose to save your name, email address and website in cookies. They are used for your convenience so that you do not have to re-enter your details when you leave another comment. These cookies will last for one year.</p>

				<h3>Embedded content from other websites</h3>
				<p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in exactly the same way as if the visitor had visited the other website.</p>
				<p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with them, including tracking your interaction with the embedded content if you have an account and are logged in to those websites.</p>

				<h3>How long we keep your data</h3>
				<p>If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can automatically recognize and approve any subsequent comments instead of keeping them in a moderation queue.</p>
				<p>For users who register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit or delete their personal information at any time (except their username which they cannot change). Website administrators can also see and edit this information.</p>

				<h3>What rights do you have over your data</h3>
				<p>If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we delete all personal data relating to you. This does not include data that we are obliged to keep for administrative, legal or security purposes.</p>
			</section>

			<CallToAction
				icon={<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"/></svg>}
				text="We're Delivering the best customer Experience"
				btnLabel="Contact Us Now"
				btnUrl="/contact"
				customClass="mb-8"
			/>
		</>
	);
}