import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import WhatsAppChatBtn from './components/WhatsAppChatBtn';
import ScrollTop from './components/ScrollTop';
import BuyNowBtn from './components/BuyNowBtn';
import Home from './views/Home';
import About from './views/About';
import Services from './views/Services';
import Projects from './views/Projects';
import Blog from './views/Blog';
import Contact from './views/Contact';
import ServiceLayout from './views/ServiceLayout';
import PostLayout from './views/PostLayout';
import Policy from './views/Policy';
import PageNotFound from './views/PageNotFound';

export default function App() {
	const location = useLocation();
	useEffect(() => {
		!location.hash ?
			setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0) :
			localStorage.removeItem('position');
	}, [location]);

	return (
		<>
			<Header />

			<main>
				<Routes location={location} key={location.pathname}>
					<Route path='/'>
						<Route index element={<Home />} />
						<Route path='about' element={<About />} />
						<Route path='services'>
							<Route index element={<Services />} />
							<Route path=':serviceName' element={<ServiceLayout />} />
						</Route>
						<Route path='projects' element={<Projects />} />
						<Route path='blog'>
							<Route index element={<Blog />} />
							<Route path=':postName' element={<PostLayout />} />
						</Route>
						<Route path='contact' element={<Contact />} />
						<Route path='policy' element={<Policy />} />
						<Route path='/*' element={<PageNotFound />} />
					</Route>
				</Routes>
			</main>

			<WhatsAppChatBtn />
			<ScrollTop />
			<Footer />

			<BuyNowBtn />
		</>
	);
}
