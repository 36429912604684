import postList from '../data/posts';

export default function CategoriesPostWidget() {
	return (
		<ul>
			<li>
				<h4>Categories</h4>
			</li>
			{postList.map((post, idx) =>
				<li key={idx}>
					<a href="#">&bull; {post.category}</a>
				</li>
			)}
		</ul>
	);
}