import { Link } from 'react-router-dom';
import SocialLinks from './SocialLinks';
import PopularPostWidget from './PopularPostWidget';
import ContactInfo from './ContactInfo';
import logo from '../imgs/logo.svg';

export default function Footer() {
	const currentDate = new Date().getFullYear();
	return (
		<footer>
			<div className="container">
				<div>
					<img src={logo} className="mb-3" alt="Tech Corp logo" />
					<p>Transforming Businesses, Empowering Futures</p>
					<p className="mb-3">Business Services & IT Solutions React js Website Template</p>
					<SocialLinks />
				</div>

				<ul>
					<li>
						<h4>Quick Link</h4>
					</li>
					<li>
						<Link to='/'>Home</Link>
					</li>
					<li>
						<Link to='/about'>About</Link>
					</li>
					<li>
						<Link to='/services'>Services</Link>
					</li>
					<li>
						<Link to='/projects'>Projects</Link>
					</li>
					<li>
						<Link to='/blog'>Blog</Link>
					</li>
					<li>
						<Link to='/contact'>Contact</Link>
					</li>
					<li>
						<Link to='/policy'>Policy</Link>
					</li>
				</ul>

				<PopularPostWidget numb={2} />

				<ContactInfo />
			</div>
			

			<p className="txt-center">&copy; Tech Corp {currentDate}. All right reserved. Developed by <Link to="/" className="txt-gradient">Travolgi</Link>.</p>
		</footer>
	);
}