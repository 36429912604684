import { Link } from 'react-router-dom';

export default function Service({ slug, icon, title, text }) {
	return (
		<article className="services">
			{icon}
			<h2>
				<Link to={`/services/${slug}`}>{title}</Link>
			</h2>
			<p>{text}</p>
			<Link to={`/services/${slug}`} className="btn">Read more</Link>
		</article>
	);
}