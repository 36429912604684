import CategoriesPostWidget from './CategoriesPostWidget';
import PopularPostWidget from './PopularPostWidget';

export default function Sidebar() {
	return (
		<aside>
			<input type="search" placeholder="Search 🔍" />

			<CategoriesPostWidget />

			<PopularPostWidget />
		</aside>
	);
}
