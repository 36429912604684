import { Helmet } from 'react-helmet';
import servicesList from '../data/services';
import Service from '../components/Service';
import SectionPageTitle from '../components/SectionPageTitle';
import SectionContact from '../components/SectionContact';
import SectionReviews from '../components/SectionReviews';
import CallToAction from '../components/CallToAction';

export default function Services() {
	return (
		<>
			<Helmet>
				<title>Services - Tech Corp: Business Services & IT Solutions React js Website Template</title>
				<meta name="description" content="Tech Corp Services for Business Services & IT Solutions to your business" />
				<meta name="keywords" content="services, it servies, solution service" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'Services'} 
				subTitle={'Our it partner'}
			/>

			<section className="container">
				<div className="grid gtc-3 g-2">
					{servicesList.map(service =>
						<Service
							key={service.slug}
							icon={service.icon}
							slug={service.slug}
							title={service.title}
							text={service.text}
						/>
					)}
				</div>
			</section>

			<SectionContact />

			<SectionReviews />

			<CallToAction
				icon={<svg className="mx-h-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22 12C22 10.6868 21.7413 9.38647 21.2388 8.1731C20.7362 6.95996 19.9997 5.85742 19.0711 4.92896C18.1425 4.00024 17.0401 3.26367 15.8268 2.76123C14.6136 2.25854 13.3132 2 12 2V4C13.0506 4 14.0909 4.20703 15.0615 4.60889C16.0321 5.01099 16.914 5.60034 17.6569 6.34326C18.3997 7.08594 18.989 7.96802 19.391 8.93848C19.7931 9.90918 20 10.9495 20 12H22Z" /><path d="M2 10V5C2 4.44775 2.44772 4 3 4H8C8.55228 4 9 4.44775 9 5V9C9 9.55225 8.55228 10 8 10H6C6 14.4182 9.58173 18 14 18V16C14 15.4478 14.4477 15 15 15H19C19.5523 15 20 15.4478 20 16V21C20 21.5522 19.5523 22 19 22H14C7.37259 22 2 16.6274 2 10Z" /><path d="M17.5433 9.70386C17.8448 10.4319 18 11.2122 18 12H16.2C16.2 11.4485 16.0914 10.9023 15.8803 10.3928C15.6692 9.88306 15.3599 9.42017 14.9698 9.03027C14.5798 8.64014 14.1169 8.33081 13.6073 8.11963C13.0977 7.90869 12.5515 7.80005 12 7.80005V6C12.7879 6 13.5681 6.15527 14.2961 6.45679C15.024 6.7583 15.6855 7.2002 16.2426 7.75732C16.7998 8.31445 17.2418 8.97583 17.5433 9.70386Z" /></svg>}
				text="We're Delivering the best customer Experience"
				btnLabel="+39 321 123456"
				btnUrl="tel:321123456"
				customClass="mb-8"
			/>
		</>
	);
}