export default [
	{
		img: require('../imgs/projects/project1.webp'),
		name: 'App Solution',
		category: 'Development',
		url: '#'
	},
	{
		img: require('../imgs/projects/project2.webp'),
		name: 'UI Design',
		category: 'Portfolio',
		url: '#'
	},
	{
		img: require('../imgs/projects/project3.webp'),
		name: 'Database Checkup',
		category: 'Cyber Security',
		url: '#'
	},
	{
		img: require('../imgs/projects/project4.webp'),
		name: 'Marketing Campains',
		category: 'Business Optimization',
		url: '#'
	},
	{
		img: require('../imgs/projects/project5.webp'),
		name: 'AI Assistant',
		category: 'Machine Learning',
		url: '#'
	},
	{
		img: require('../imgs/projects/project6.webp'),
		name: 'Hosting platform',
		category: 'Cloud',
		url: '#'
	}
]
