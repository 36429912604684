export default function ProjectOverlay({ img, projectName, category, url }) {
	return (
		<article className="project browserbar">
			<img src={img} alt={`Project ${projectName} - Tech Corp React js template`} />
			
			<div className="info">
				<h2 className="mb-0">{projectName}</h2>
				<p>{category}</p>
				<a href={url} className="btn self-end">Show</a>
			</div>
		</article>
	);
}