import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import postsList from '../data/posts';
import servicesList from '../data/services';
import projectsList from '../data/projects';
import Service from '../components/Service';
import PostPreview from '../components/PostPreview';
import ProjectOverlay from '../components/ProjectOverlay';
import SectionReviews from '../components/SectionReviews';
import SectionContact from '../components/SectionContact';
import mission from '../imgs/mission.webp';


export default function Home() {
	return (
		<>
			<Helmet>
				<title>Tech Corp: Business Services & IT Solutions React js Website Template</title>
				<meta name="description" content="Welcome to Tech Corp, where innovation meets reliability, we are your trusted partner for business services and IT solutions" />
				<meta name="keywords" content="business, it, it solution, web services, react template" />
			</Helmet>

			<section id="slider">
				<div className="container">
					<div className="grid gtc-2-1">
						<article>
							<h1>Transforming Businesses, <span className="txt-gradient">Empowering Futures</span></h1>
							<h2 className="w-60 fw-400">Business Services & IT Solutions React js Website Template</h2>

							<HashLink to="#contact" className="btn">Contact us now</HashLink>
						</article>
					</div>

					<div className="flex space-between g-2">
						<h2><span className="pictogram" /> IT Consultancy</h2>
						<h2><span className="pictogram" /> Software Development</h2>
						<h2><span className="pictogram" /> Cybersecurity</h2>
					</div>
				</div>
			</section>

			<section>
				<span className="sr-only offset-nav" id="mission" />
				<div className="container grid g-2">
					<div className="print flex">
						<img src={mission} alt="" />
					</div>

					<article>
						<h1>Our <span className="txt-gradient">Mission</span></h1>
						<p>Welcome to Tech Corp, where innovation meets reliability. Founded on the principles of cutting-edge technology and unwavering commitment to excellence, we are your trusted partner for business services and IT solutions.</p>
						<p className="mb-3">Our mission is to drive your success through innovative and tailored IT solutions. We believe in the power of technology to propel businesses forward, and we are committed to delivering solutions that not only meet but exceed your expectations.</p>

						<Link to="/about" className="btn">READ MORE</Link>
					</article>
				</div>
			</section>
			
			<span className="sr-only offset-nav" />
			<section className="accent-start">
				<div className="container">
					<h1 className="txt-gradient">What Sets Us Apart</h1>

					<div className="flex g-1 space-between mb-4">
						<h2><span className="pictogram-top" /> Expertise</h2>
						<h2><span className="pictogram-top" /> Collaboration</h2>
						<h2><span className="pictogram-top" /> Proven Track Record</h2>
						<h2><span className="pictogram-top" /> Cybersecurity</h2>
						<h2><span className="pictogram-top" /> Cloud</h2>
					</div>

					<div className="grid g-2 gtc-1-2-1 mb-4">
						<h2 className="self-start mb-0">Why Choose Tech Corp</h2>

						<div>
							<p>Choosing Tech Corp means choosing a partner dedicated to your success. We blend technical expertise with a client-centric approach, ensuring that every solution we provide contributes to your business growth.</p>
							<p className="mb-0">Join us on the journey to technological excellence. Together, let's shape a future where your business not only survives but thrives in the digital era.</p>
						</div>

						<Link to="/services" className="btn self-end">OUR SERVICES</Link>
					</div>

					<div className="grid gtc-3 g-2">
						{servicesList.map(service =>
							<Service
								key={service.slug}
								icon={service.icon}
								slug={service.slug}
								title={service.title}
								text={service.text}
							/>
						)}
					</div>
				</div>
			</section>

			<section>
				<div className="container">
					<div className="flex g-2 space-between">
						<h1>Our <span className="txt-gradient">Projets</span></h1>
						<Link to="projets" className="btn mb-3">EXPLORE MORE</Link>
					</div>

					<div className="grid g-3">
						{projectsList.slice(0, 4).map((project, idx) =>
							<ProjectOverlay
								key={idx}
								img={project.img}
								projectName={project.name}
								category={project.category}
								url={project.url}
							/>
						)}
					</div>
				</div>
			</section>

			<section className="bg-line">
				<div className="container txt-center">
					<h3>Pioneering Tomorrow's Solutions, Empowering Your</h3>
					<h1 className="fs-100">Business Today</h1>
				</div>
			</section>
			
			<section>
				<span className="sr-only offset-nav" id="blog" />
				<div className="container">
					<div className="flex g-2 space-between">
						<h1>Latest <span className="txt-gradient">Posts</span></h1>
						<Link to="posts" className="btn mb-3">SEE ALL</Link>
					</div>

					<div className="grid g-2 gtc-3">
						{postsList.slice(0,3).map((post, idx) =>
							<PostPreview
								key={idx}
								img={post.imgs.v}
								title={post.title}
								slug={post.slug}
								date={post.date}
								category={post.category}
							/>
						)}
					</div>
				</div>
			</section>

			<SectionReviews />
			
			<span className="sr-only offset-nav" id="contact" />
			<SectionContact />
		</>
	);
}
