import { Link } from 'react-router-dom';

export default function PostPreview({ img, title, slug, date, category }) {
	return (
		<article className="post-preview" style={{ '--bg-post-preview': `url(${img})` }}>
			<Link to={`/blog/${slug}`}>
				<time>{date}</time>
				<h2>{title}</h2>
				<div className="post-category">{category}</div>
			</Link>
		</article>
	);
}