import { HashLink } from 'react-router-hash-link';

export default function PageIndex({ links }) {
	return (
		<>
			<h2>Index</h2>
			<ul className="list mb-3">
				{links.map(link =>
					<li key={link.hash}>
						<HashLink to={`#${link.hash}`}>{link.title}</HashLink>

						{link.subidx &&
							<ul>
								{link.subidx.map(sublink =>
									<li key={sublink.hash}>
										<HashLink to={`#${sublink.hash}`}>{sublink.title}</HashLink>
									</li>
								)}
							</ul>
						}
					</li>
				)}
			</ul>
		</>
	);
}