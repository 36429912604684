import ExamplePost from '../views/posts/ExamplePost';
import ExamplePost2 from '../views/posts/ExamplePost2';

export default [
	{
		imgs: {
			v: require('../imgs/posts/post-example6.webp'),
			h: require('../imgs/posts/post-example6-h.webp')
		},
		title: 'Enhancing Productivity with Efficient Business Services',
		slug: 'enhancing-productivity-with-efficient-business-services',
		date: '25 Dec 2023',
		category: 'productivity',
		content: <ExamplePost2 />
	},
	{
		imgs: {
			v: require('../imgs/posts/post-example2.webp'),
			h: require('../imgs/posts/post-example2-h.webp')
		},
		title: 'Optimizing Operations with Reliable IT Solutions',
		slug: 'optimizing-operations-with-reliable-it-solutions',
		date: '7 Dec 2023',
		category: 'optimization',
		content: <ExamplePost2 />
	},
	{
		imgs: {
			v: require('../imgs/posts/post-example3.webp'),
			h: require('../imgs/posts/post-example3-h.webp')
		},
		title: 'Unlocking Business Potential with Advanced IT Services',
		slug: 'unlocking-business-potential-with-advanced-it-services',
		date: '23 Nov 2023',
		category: 'business',
		content: <ExamplePost />
	},
	{
		imgs: {
			v: require('../imgs/posts/post-example4.webp'),
			h: require('../imgs/posts/post-example4-h.webp')
		},
		title: 'Streamlining Business Processes through Strategic IT Services',
		slug: 'streamlining-business-processes-through-strategic-it-services',
		date: '4 Nov 2023',
		category: 'strategic',
		content: <ExamplePost2 />
	},
	{
		imgs: {
			v: require('../imgs/posts/post-example5.webp'),
			h: require('../imgs/posts/post-example5-h.webp')
		},
		title: 'Maximizing Profitability with Tailored Business Solutions',
		slug: 'maximizing-profitability-with-tailored-business-solutions',
		date: '30 Sep 2023',
		category: 'profit',
		content: <ExamplePost />
	},
	{
		imgs: {
			v: require('../imgs/posts/post-example1.webp'),
			h: require('../imgs/posts/post-example1-h.webp')
		},
		title: 'The Importance of IT Solutions for Business Growth',
		slug: 'the-importance-of-it-solutions-for-business-growth',
		date: '1 Sep 2023',
		category: 'growth',
		content: <ExamplePost />
	}
]