import { Helmet } from 'react-helmet';
import projectsList from '../data/projects';
import SectionPageTitle from '../components/SectionPageTitle';
import ProjectOverlay from '../components/ProjectOverlay';
import SectionReviews from '../components/SectionReviews';
import SectionContact from '../components/SectionContact';

export default function Projects() {
	return (
		<>
			<Helmet>
				<title>Projects - Tech Corp: Business Services & IT Solutions React js Website Template</title>
				<meta name="description" content="Tech Corp Business Services & IT Solutions Projects" />
				<meta name="keywords" content="projects, it projects, business plan, business projects" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'Projects'} 
				subTitle={'We are unstoppable'}
			/>

			<section className="big-container">
				<div className="grid gtc-3 g-3">
					{projectsList.map((project, idx) =>
						<ProjectOverlay
							key={idx}
							img={project.img}
							projectName={project.name}
							category={project.category}
							url={project.url}
						/>
					)}
				</div>
			</section>
			
			<SectionReviews col={2} />

			<SectionContact />
		</>
	);
}