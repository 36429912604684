export default [
	{
		question: 'What IT solutions does your company offer',
		answer: 'Our company offers a wide range of IT solutions including network infrastructure setup, cloud computing services, cybersecurity solutions, software development, and IT consulting services.'
	},
	{
		question: 'How can IT solutions benefit my business',
		answer: 'IT solutions can benefit your business by improving operational efficiency, enhancing productivity, ensuring data security, providing access to advanced technologies, and enabling better decision-making through data analytics.'
	},
	{
		question: 'Are your IT solutions customizable to suit our business requirements',
		answer: 'Yes, our IT solutions are highly customizable and tailored to meet the unique requirements of each business. We work closely with our clients to understand their specific needs and provide customized solutions accordingly.'
	},
	{
		question: 'Do you provide ongoing support and maintenance for the IT solutions',
		answer: 'Yes, we offer comprehensive support and maintenance services for the IT solutions we provide. Our team of skilled professionals ensures that your systems are up-to-date, secure, and performing optimally at all times.'
	},
	{
		question: 'How do you ensure data privacy and security with your IT solutions',
		answer: 'We prioritize data privacy and security and implement industry-standard encryption techniques, multi-factor authentication, and robust security protocols to safeguard your sensitive information. We follow strict data protection policies to ensure the confidentiality and integrity of your data.'
	}
]