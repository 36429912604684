import { Helmet } from 'react-helmet';
import SectionPageTitle from '../components/SectionPageTitle';
import SectionContact from '../components/SectionContact';
import CallToAction from '../components/CallToAction';

export default function Contact() {
	return (
		<>
			<Helmet>
				<title>Contact Us - Tech Corp: Business Services & IT Solutions React js Website Template</title>
			</Helmet>

			<SectionPageTitle
				pageTitle={'Contact Us'} 
				subTitle={'Do you need help?'}
			/>

			<SectionContact
				bg={false}
			/>

			<section className="container grid gtc-2-1 g-2 align-center pt-0">
				<iframe title="Maps" src="https://www.google.com/maps?q=Venice+Italy&t=&z=8&ie=UTF8&iwloc=&output=embed" />

				<ul>
					<li>
						<h3 className='mb-3'>Tech Corp Solutions:</h3>
					</li>
					<li>
						<h2><span className="pictogram" /> IT Consultancy</h2>
					</li>
					<li>
						<h2><span className="pictogram" /> Software Development</h2>
					</li>
					<li>
						<h2 className="mb-0"><span className="pictogram" /> Cybersecurity</h2>
					</li>
				</ul>
			</section>

			<CallToAction
				icon={<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"/></svg>}
				text="We're Delivering the best customer Experience"
				btnLabel="Explore"
				btnUrl="/services"
				customClass="mb-8"
			/>
		</>
	);
}